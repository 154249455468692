import { AdminRepairerExternalCode } from 'api/resources/models/AutoGenerated';

export const getExternalCodeValue = (
  system: string,
  repairerExternalCodes?: AdminRepairerExternalCode[] | null
) => {
  return (
    repairerExternalCodes?.find(
      (externalCode) => externalCode.externalSystem === system && 
                        externalCode.enabled === true
    )?.externalCode ?? ''
  );
};

export function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
  return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
}
